html, body, #root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-control {
  margin: 6px 0 20px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-control.no-margin {
  margin: 6px 0 0px 0;
}

.flex {
  flex: 1
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-col-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-0 {
  gap: 0px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.p {
  padding: 20px
}

.p-t {
  padding-top: 20px
}

.p-b {
  padding-bottom: 20px
}

.p-l {
  padding-left: 20px
}

.p-r {
  padding-right: 20px
}